<template>
  <div class="terms-of-use">
    <h1>TERMO DE ACEITE PARA MONITORAMENTO DE SAÚDE</h1>
    <br>
    <p>Eu, Usuário, declaro ter ciência deste Termo de Aceite dos Serviços prestados pela
      Plataforma de MONITORAMENTO ON-LINE DE SAÚDE, oferecidos
      MONITORAMENTO INTELIGENTE POR SENSOR (MIS), pessoa jurídica
      devidamente inscrita no CNPJ nº 26.134.051/0001-08, situada no VENÂNCIO 2000,
      torre B50/60, - 8 andar, sala 810, Brasília/DF, CEP 70.333-900. Declaro que após a
      leitura completa e atenta às regras deste Termo de Aceite, do Termo de Uso e
      Política de Privacidade, de forma livre e expressa, aceito eletronicamente todas as
      cláusulas atuais, assim como futuras regras, disponibilizadas nas atualizações da
      Plataforma de MONITORAMENTO ON-LINE DE SAÚDE.</p>
    <br>
    <p>1. CONSIDERAÇÕES INICIAIS SOBRE MONITORAMENTO DE SAÚDE:</p>
    <ul>
      <li>1.1. O Monitoramento on-line não se responsabiliza pelo tratamento ou piora de
        saúde do usuário por se tratar, exclusivamente, de um serviço de alertas para
        PREVENÇÃO/ACOMPANHAMENTO dos sinais vitais do usuário, ou seja, um
        TRATAMENTO.</li>
      <li>
        1.2. Os dados informados no ato do cadastro pelo usuário, passaram a ser parte
        integrante deste termo;
      </li>
      <li>
        1.3. O usuário poderá adquirir o serviço:
      </li>
      <ul>
        <li>a) On-line, no site MONITORAMENTO INTELIGENTE POR SENSOR (MIS);</li>
        <li>b) Presencialmente, em um dos postos;</li>
        <li>c) Por telefone;</li>
        <li>d) Por grupos/associações conveniados e/ou revendedores regularmente
          autorizados pela plataforma.</li>
      </ul>
      <li>1.4. Declaro ter ciência que:</li>
      <ul>
        <li>a) O serviço de monitoramento prestado é apenas um serviço de
          acompanhamento/prevenção e não tratamento;</li>
        <li>b) Para o bom aproveitamento e análise dos sinais monitorado pela plataforma
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS), devo informar
          meus dados médicos e possíveis doenças já existentes, não omitindo
          nenhuma informação acerca dos meus sinais e sintomas;</li>
        <li>c) É de minha inteira responsabilidade acatar ou não a orientação de buscar
          ajuda médica, caso seja recomendado pela Plataforma de Monitoramento,
          isentando a MONITORAMENTO INTELIGENTE POR SENSOR (MIS) de
          qualquer responsabilidades;</li>
        <li>d) Autorizo a utilização de E-mail, WhatsApp ou SMS como forma de
          comunicação de remessa de informação, estando eu, usuário, ciente das
          vulnerabilidades do sistema de eleição quanto ao sigilo da informação, e
          assumindo os riscos decorrentes, isentando a MONITORAMENTO
          INTELIGENTE POR SENSOR (MIS) de quaisquer responsabilidades;</li>
        <li>e) Tenho ciência que meus dados trafegarão pela internet, e estarão sujeitos a
          nossa Política de Privacidade de Dados.</li>
        <li>f) Autorizo que meus dados médicos coletados por esta plataforma sejam
          registrados em meu prontuário e armazenados em ambientes seguros,
          excepcionados os casos de ataques de hackers ou outras ameaças
          cibernéticas incontroláveis, hipóteses em que desde já, isento a
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS) SAÚDE LTDA de
          quaisquer responsabilidades.</li>
      </ul>
      <li>1.5 Para ser possível o atendimento de Monitoramento, as seguintes condições
        necessárias devem ser atendidas cumulativamente USUÁRIO, dentre elas:</li>
      <ul>
        <li>a) Assinatura do Termo de Consentimento Livre e Esclarecido;</li>
        <li>b) Assinatura da Política de Privacidade;</li>
        <li>c) Possuir smartphone e manter ele sempre conectado ao dispositivo;</li>
        <li>d) Acesso à internet de qualidade;</li>
        <li>e) Esteja utilizando o dispositivo (no pulso) para que ele faça a leitura dos sinais
          vitais;</li>
        <li>f) Forneça dados de saúde de forma integral: remédios, doenças
          pre-existentes, práticas de atividade física;</li>
        <li>g) Informe no ato do cadastro contato de emergência.</li>
      </ul>
    </ul>
    <br>
    <p>2. DA CONTRAPRESTAÇÃO FINANCEIRA</p>
    <ul>
      <li>2.1. Declaro, estar ciente e em concordância que:</li>
      <ul>
        <li>
          a) para que ocorra a prestação do serviço de monitoramento, o USUÁRIO deve
          estar em dia com o pagamento do acordo financeiro firmado.
        </li>
        <li>
          b) nos casos em que a Plataforma MONITORAMENTO INTELIGENTE POR
          SENSOR (MIS) indicar ao usuário que este necessita de acompanhamento
          médico e o usuário queira utilizar os serviços de teleconsulta, este serviço
          adicional será contratado e remunerado a parte, de forma particular, sendo
          um serviço não incluso no serviço de monitoramento on-line
        </li>
      </ul>
      <li>2.2. Os preços do Monitoramento on-line oferecido pela Plataforma
        MONITORAMENTO INTELIGENTE POR SENSOR (MIS), bem como as demais
        condições comerciais, poderão ser consultados no site da MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS).</li>
      <li>
        2.3. O MONITORAMENTO INTELIGENTE POR SENSOR (MIS) reserva a si o
        direito de alterar em qualquer momento os valores praticados, devendo a referida
        alteração ser comunicada ao USUÁRIO.
      </li>
      <li>
        2.4. Em caso de não pagamento por parte do USUÁRIO do serviço contratado,
        respectivo serviço será bloqueado, interrompendo todos os monitoramentos e
        isentando a MONITORAMENTO INTELIGENTE POR SENSOR (MIS) de quaisquer
        responsabilidades
      </li>
    </ul>
    <br>
    <p>3. DAS OBRIGAÇÕES DO CONTRATADO PARA O USO DO SERVIÇO DE
      MONITORAMENTO DE SAÚDE</p>
    <ul>
      <li>3.1. O MONITORAMENTO INTELIGENTE POR SENSOR (MIS) compromete-se
        após a contratação do serviço:</li>
      <ul>
        <li>
          a) Disponibilizar o dispositivo ao usuário para monitoramento;
        </li>
        <li>b) Liberar o link para instalação do aplicativo no smartphone;</li>
        <li>c) Realizar o cadastro com todos os dados iniciais do usuário, bem como seu
          histórico médico; </li>
        <li>
          d) Colocar uma central de monitoramento 24 horas para realizar monitoramento
          dos sinais vitais do USUÁRIO;
        </li>
        <li>e) Entrar em contato com o usuário sempre que houver um alerta dos sinais
          vitais fora do padrão;</li>
        <li>f) Caso o USUÁRIO não responda a chamada do atendente, este entrará em
          contato com o número cadastrado como contato de emergência;</li>
      </ul>
      <li>3.2. Realizada a contratação do serviço, o usuário receberá toda orientação para
        melhor uso dos dispositivos e instalação do app.</li>
      <li>3.3. O MONITORAMENTO INTELIGENTE POR SENSOR (MIS) enviará seus
        melhores esforços para a manutenção da disponibilidade da PLATAFORMA DE
        MONITORAMENTO ON-LINE. No entanto, poderá ocorrer, eventualmente, alguma
        indisponibilidade temporária decorrente de manutenção necessária ou mesmo
        gerada por motivo de força maior, como desastres naturais, caso fortuito, falhas nos
        sistemas de comunicação e acesso à internet ou fator de terceiro que fogem de sua
        esfera de vigilância e responsabilidade, não sendo o MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS) responsável por qualquer desses eventos, nem
        pela indisponibilidade da PLATAFORMA MONITORAMENTO INTELIGENTE POR
        SENSOR (MIS). Caso isso ocorra, o MONITORAMENTO INTELIGENTE POR
        SENSOR (MIS) fará o que estiver ao seu alcance para estabelecer o acesso o mais
        breve possível, dentro das limitações técnicas de seus serviços e serviços de
        terceiros.</li>
      <li>3.4.Em nenhuma hipótese o MONITORAMENTO INTELIGENTE POR SENSOR
        (MIS) se responsabilizará:</li>
      <ul>
        <li>a) Por serviços prestados por parceiros ou terceiros, sendo o USUÁRIO
          responsável por verificar e estar de contratos, termos e condições do
          parceiro/terceiro para contratação e uso do respectivo serviço;</li>
        <li>b) Pelas falhas de acesso à PLATAFORMA DE MONITORAMENTO
          INTELIGENTE POR SENSOR (MIS) decorrentes de circunstâncias
          relacionadas à indisponibilidade da internet geral, quedas de energia, mau
          funcionamento digital ou físico de qualquer rede de telecomunicações,
          interrupções ou suspensões de conexão e falhas nos softwares e/ou
          hardwares utilizados pelos USUÁRIOS;</li>
        <li>c) Por quaisquer problemas, bugs, glitches ou funcionamentos indevidos que
          ocorrem nos dispositivos e equipamentos dos USUÁRIOS e sejam
          resultantes do uso incorreto;</li>
        <li>d) Por erros de sistema que alterem dados ou informações de serviços,
          inclusive preços informados na PLATAFORMA DE MONITORAMENTO
          INTELIGENTE POR SENSOR (MIS), e por comprar e pagamentos
          reprovados pelas instituições financeiras;</li>
        <li>e) Nos casos em que determinado(s) serviço(s) for(em) oferecido(s) a preços
          que claramente não condizem com a realidade praticada no mercado - erro
          crasso - sendo que o MONITORAMENTO INTELIGENTE POR SENSOR
          (MIS), bem como o(s) parceiro(s) que o(s) anunciou(aram), se eximem da
          responsabilidade de cumprir com a oferta;</li>
        <li>f) Por qualquer dano direto ou indireto ocasionado por eventos de terceiros,
          como ataque Hackers, falhas no sistema, no servidor ou na conexão à
          internet, inclusive por questões de softwares maliciosos, como vírus, cavalos
          de tróia e outros que possam, de algum modo, danificar o equipamento ou a
          conexão dos USUÁRIOS em decorrência do acesso, utilização ou navegação
          na PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR
          (MIS), bem como a transferência de dados e arquivos nela contidos;</li>
        <li>g) Nos casos em que seja necessária manutenção emergencial, esta poderá
          ocorrer dentro do horário comercial ou de pico, e sem aviso prévio, sendo
          que, nestes casos, o MONITORAMENTO INTELIGENTE POR SENSOR
          (MIS) não será responsabilizada pela indisponibilidade por haver fundada
          justificativa para que a manutenção ocorresse dessa forma.</li>
      </ul>
      <li>3.5. O MONITORAMENTO INTELIGENTE POR SENSOR (MIS) poderá, de acordo
        com seus objetivos de negócio, modificar, suspender ou descontinuar
        temporariamente as funcionalidades, serviços e/ou conteúdo da PLATAFORMA DE
        MONITORAMENTO INTELIGENTE POR SENSOR (MIS), segundo sua exclusiva
        conveniência, sempre que entender necessário para oferecer os melhores serviços
        ao USUÁRIO.</li>
      <li>3.6. O MONITORAMENTO INTELIGENTE POR SENSOR (MIS) poderá, a qualquer
        tempo, alterar, adequar ou remodelar o conteúdo, layout, efetuar paralisações
        programadas para manutenções, funcionalidades e/ou ferramentas relacionadas à
        PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS), desde
        que esteja em conformidade com a legislação brasileira, em especial a Lei Federal
        nº 12.965 de 2014 (Marco Civil da Internet) e a Lei nº 13.709 de 2018 (Lei Geral de
        Proteção de Dados).</li>
      <li>3.7. O MONITORAMENTO INTELIGENTE POR SENSOR (MIS) poderá restringir,
        limitar ou impedir, por qualquer meio ou forma, o acesso de um ou mais USUÁRIOS
        à PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS),
        conforme seu exclusivo citério, especialmente nos casos repetidos de suspeitas de
        fraude ou quaisquer ações que prejudiquem ou possam prejudicar o funcionamento
        da PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS).</li>
      <li>3.8. A PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS),
        reserva a si o direito de, a qualquer momento e sem aviso prévio, suspender os
        serviços do USUÁRIO que infringir qualquer das disposições aqui previstas, praticar,
        auxiliar ou instigar atos que venham a prejudicar o bom funcionamento da
        PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS), ou vier
        a utilizar-se de atos ilícitos para ações na PLATAFORMA DE MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS), incluindo, mas não se limitando a, inserção de
        dados falsos e/ou incorretos, a utilização de cartões fraudulentos ou inexistentes,
        dentre outros atos que venham a infringir qualquer disposição legal prevista no
        ordenamento jurídico.</li>
    </ul>
    <br>
    <p>4. DAS OBRIGAÇÕES RESPONSABILIDADES DOS USUÁRIOS</p>
    <ul>
      <li>4.1. Os USUÁRIOS são responsáveis por e se obrigarem a:</li>
      <ul>
        <li>a) Utilizar com retidão e de forma ética a PLATAFORMA DE
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS), respeitando as
          condições que regem a sua utilização e finalidade;</li>
        <li>b) Fornecer informações e dados cadastrais precisos, verdadeiros, completos e
          atualizados, além de informar o canal de contato a ser acionado pela
          PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS),
          para o melhor cumprimento dos serviços, comprometendo-se a atualizá-las
          sempre que houver alguma alteração por e-mail ou telefone, sendo
          responsável pelas sanções administrativas, civis e penais que eventualmente
          resultarem de incorreção desses dados, assim como danos em sua saúde;</li>
        <li>c) Dispor de dispositivos e equipamentos compatíveis com a PLATAFORMA DE
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS), serviço de
          conexão à Internet com antivírus e firewall habilitados, e softwares
          devidamente atualizados;</li>
        <li>d) Deixar seus sistemas de AntiSpam, filtros similares ou configurações de
          redirecionamento de mensagens ajustados de modo que não interfiram no
          recebimento dos comunicados e materiais da PLATAFORMA DE
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS), não sendo
          aceitável nenhuma escusa, caso não tenha tido acesso a algum e-mail ou
          mensagem eletrônica em virtude dos recursos mencionados;</li>
        <li>e) Respeitar todos os direitos de propriedade intelectual de titularidade da
          PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS),
          bem como todos os direitos referentes a terceiros;</li>
        <li>f) Não acessar as áreas de programação da PLATAFORMA DE
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS), seu banco de
          dados ou qualquer outro conjunto de informações que faça parte da atividade
          de administração;</li>
        <li>g) Não realizar ou permitir que se realize engenharia reversa, nem traduzir,
          decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar,
          divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das
          ferramentas da PLATAFORMA DE MONITORAMENTO INTELIGENTE POR
          SENSOR (MIS) e de suas funcionalidades;</li>
        <li>h) Não utilizar softwares spider, ou de mineração de dados, de qualquer tipo ou
          espécie, além de outro aqui não tipificado, mas que atue de modo
          automatizado, tanto para realizar operações massificadas como para
          quaisquer outras finalidades;</li>
        <li>i) Não transmitir materiais contendo vírus de computador ou outros códigos,
          arquivos, scripts, agentes ou programas de computador maliciosos;</li>
        <li>j) Não explorar maliciosamente a segurança da plataforma para a prática de
          atos ilícitos, proibidos pela lei e pelo presente Termo, lesivos aos direitos e
          interesses da plataforma e/ou de terceiros, ou que, de qualquer forma, possa
          danificar, inutilizar, sobrecarregar ou deteriorar a PLATAFORMA DE
          MONITORAMENTO INTELIGENTE POR SENSOR (MIS);</li>
        <li>k) Responsabilizar-se exclusivamente por qualquer dano por si causado à
          PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS)
          ou a terceiros em virtude do não cumprimento das obrigações aqui dispostas
          ou da não veracidade das informações prestadas, não havendo o que se falar
          em subsidiariedade da obrigação, tampouco em solidariedade da
          PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS).</li>
      </ul>
      <li>4.2. As funcionalidades que compõem a PLATAFORMA DE MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS) oferecidas na forma de prestação de serviço,
        não conferem ao USUÁRIO nenhum direito sobre o software utilizado, seu conteúdo
        ou sobre as estruturas de informática que o sustentam.</li>
      <li>4.3. O USUÁRIO reconhece como de propriedade da MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS), o banco de dados onde serão armazenados e
        tratados os dados inseridos dentro da PLATAFORMA DE MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS).</li>
    </ul>
    <br>
    <p>5. DO TRATAMENTO DOS DADOS PESSOAIS DO USUÁRIO</p>
    <ul>
      <li>5.1. A PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS)
        possui documento próprio, denominado política de Privacidade, que regula o
        tratamento dos dados coletados na Plataforma de monitoramento INTELIGENTE
        POR SENSOR (MIS), sendo parte integrante e inseparável do presente Termo,
        podendo ser acessada pelo Link https://mis.spezi.com.br/terms-of-use</li>
    </ul>
    <br>
    <p>6. DAS DISPOSIÇÕES FINAIS</p>
    <ul>
      <li>6.1. Em nenhum caso, a PLATAFORMA DE MONITORAMENTO INTELIGENTE
        POR SENSOR (MIS), seus representantes legais, diretores, funcionários, parceiros,
        agentes, fornecedores, ou licenciados, serão responsabilizados por quaisquer danos
        diretos ou indiretos, incidentais, especiais, consequenciais ou punitivos, incluindo,
        sem limitação, danos materiais ou lucros cessantes, ou outras perdas monetária e
        intangíveis, relacionadas a qualquer reclamação, ação judicial ou outro
        procedimento tomado em relação à utilização do serviço, conteúdo, funcionalidades
        ou ferramentas de PLATAFORMA DE MONITORAMENTO INTELIGENTE POR
        SENSOR (MIS), bem como, pela correção ou inexatidão resultantes de quaisquer
        elementos:</li>
      <ul>
        <li>a) dano e prejuízos que o usuário ou terceiros possam experimentar em
          decorrência da aplicação de qualquer conduta sugerida pela análise do caso
          clínico;</li>
        <li>b) danos e prejuízos que o usuário ou terceiros possam experimentar em
          decorrência do uso da plataforma em desconformidade com este termo, o
          termo de uso ou com a política de privacidade lei, os costumes ou a ordem
          pública;</li>
        <li>c) pela incapacidade de acessar ou usar o serviço;</li>
        <li>d) danos e prejuízos que o usuário ou terceiros possam experimentar em
          decorrência de falhas no aplicativo, inclusive decorrentes de falhas no
          sistema, no servidor ou na conexão de rede, ou ainda de interações
          maliciosas como vírus que possam danificar o equipamento ou acessar
          informações do equipamento do usuário;</li>
        <li>e) danos e prejuízos que o usuário ou terceiros possam experimentar em
          decorrência da indisponibilidade do profissional de saúde ou por não
          conseguir se comunicar pela teleconsulta, dentro dos períodos demarcados
          no agendamento.</li>
      </ul>
      <li>6.2. Declaro que a PLATAFORMA DE MONITORAMENTO INTELIGENTE POR
        SENSOR (MIS) poderá coletar e utilizar dados técnicos e informações relacionadas
        - incluindo, mas sem limitação, informação técnica sobre o seu dispositivo, sistema
        e software de aplicativo e periféricos - que são recolhidos periodicamente para
        facilitar a disponibilidade de atualizações de software, suporte de produto e outros
        serviços prestados ao Usuário (se houver), relacionados com a Plataforma. A
        PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS) poderá
        utilizar esta informação, desde que esteja em um formato que não identifiquem você
        pessoalmente, para melhoras dos seus produtos ou para fornecer serviços ou
        tecnologias ao Usuário.</li>
      <li>6.3. Declaro que reconheço e aceito que na medida em que não for proibido por lei,
        em nenhuma hipótese, a plataforma será responsabilizada por ferimentos pessoais
        ou quaisquer danos incidentais, especiais, indiretos ou consequenciais, incluindo,
        sem limitação, danos por lucros cessantes, corrupção ou perda de dados, falha na
        transmissão ou recebimento de quaisquer outros danos ou perdas comerciais
        indiretos, decorrentes de ou relacionados ao seu uso ou incapacidade de usar o
        aplicativo licenciado, Independente da causa, independente da teoria da
        responsabilidade (contratual, fraude ou outra) e mesmo se tiver sido avisado sobre a
        possibilidade de tais danos.</li>
      <li>6.4. A tolerância do eventual descumprimento de qualquer disposição no presente
        documento não constituirá novação das obrigações aqui estipuladas, nem impedirá
        ou inibirá a exigibilidade das mesmas a qualquer tempo. Caso alguma disposição do
        presente documento seja julgada inaplicável ou sem feito, o restante do documento
        continua a viger, sem a necessidade de medida judicial que declare tal assertiva.</li>
      <li>6.5. Estes termos não criam qualquer outra modalidade de vínculo entre os
        USUÁRIOS e a PLATAFORMA DE MONITORAMENTO INTELIGENTE POR
        SENSOR (MIS), inclusive, e sem limitação, sociedade, joint-venture, mandato,
        representação, parceria, consórcio, associação, formação de grupo econômico,
        vínculo empregatício ou similar. A PLATAFORMA DE MONITORAMENTO
        INTELIGENTE POR SENSOR (MIS) permanecerá uma entidade independente e
        autônoma.</li>
      <li>6.6. Havendo qualquer conflito entre este Termo e o Termo de Uso, prevalecerá o
        documento mais específico.</li>
      <li>6.7. A PLATAFORMA DE MONITORAMENTO INTELIGENTE POR SENSOR (MIS)
        tem, como base a data e horários oficiais de Brasília/DF.</li>
    </ul>
    <br>
    <p>
      POR FIM, EU USUÁRIO E/OU RESPONSÁVEL DECLARO TER LIDO E
      ENTENDIDO AS ORIENTAÇÕES CONTIDAS NO PRESENTE TERMO, AS QUAIS
      ENTENDIDO COMPLETAMENTE E ACEITO ELETRONICAMENTE TODAS AS
      CLÁUSULAS DESTE TERMO E DEMAIS POLÍTICAS DISPONÍVEIS NA
      PLATAFORMA, PORTANTO DOU MEU EXPRESSO E PLENO CONSENTIMENTO
      PARA A REALIZAÇÃO DA TELECONSULTA.
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse"
};
</script>

<style scoped>
.terms-of-use {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.terms-of-use h1,
h2 {
  color: #2c3e50;
}
</style>
